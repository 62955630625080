export const linkRegex = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  'i'
)

export const labelRegex = new RegExp('^[0-9A-Za-zÀ-ÖØ-öø-ÿ ()\\-_\\[\\]\\/\\\\]{1,32}$', 'i')

export const nameRegex = new RegExp('^[0-9A-Za-zÀ-ÖØ-öø-ÿ ()\\-_\\[\\]\\/\\\\]{4,128}$', 'i')

export const emailRegex = new RegExp(/^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,24}$/, 'i')
