import { emailRegex, labelRegex, linkRegex, nameRegex } from '@/constants/Regex.constants'

/**
 * A very permissive URL format validation.
 * It checks if the URL starts with a protocol http:// or https://
 * and does not contain any spaces.
 *
 * @param {string} url - The URL to validate.
 * @returns {boolean}
 */
export const isURLFormatValid = (url: string): boolean => {
  if (url === '') {
    return true
  }

  const re = new RegExp('^[Hh][Tt][Tt][Pp][Ss]?://[^\\s]+$')

  return !!url.match(re)
}

export const formatCost = (value: number | string): string | undefined => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  if (!isNaN(value) && value > 0) {
    return `${parseFloat(value.toFixed(2))} k€`
  }
}

export const formatWorkload = (value: number | string, lang: string): string | undefined => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  if (!isNaN(value) && value > 0) {
    const d = lang === 'fr' ? 'j' : 'd'

    if (value) {
      return parseFloat(value.toFixed(2)) + ' ' + d
    }
  } else {
    return ''
  }
}

export const isEmailFormatValid = (email: string): boolean => {
  return emailRegex.test(email)
}

export const isNameFormatValid = (name: string): boolean => {
  return nameRegex.test(name)
}

export const isLabelFormatValid = (label: string): boolean => {
  return labelRegex.test(label)
}

export const isLinkFormatValid = (link: string): boolean => {
  return linkRegex.test(link)
}
